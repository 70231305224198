import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
1;
import { VueJsonp } from "vue-jsonp";
import VueAMap from "vue-amap";
Vue.use(VueJsonp);
Vue.use(ElementUI);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
